import React from "react"
import { Link } from "gatsby"
import { FaHome, FaTags } from "react-icons/fa"

import Layout from "../components/Layout"
import LinkContainer from "../components/LinkContainer"
import PageHeading from "../components/PageHeading"
import SubHeading from "../components/SubHeading"
import styled from "@emotion/styled"

const Li = styled.li`
  list-style: none;
  padding: 10px;
  &:nth-of-type(even) {
    border: 4px solid var(--purple);
  }
    a {
      color: var(--color);
      :hover {
        color: #747474;
      }
    }
  }
  &:nth-of-type(odd) {
    border: 4px solid var(--blue);
  }
`

const TagList = styled.ul`
  display: flex;
  flex-direction: row;
  justify-content: left;
  align-items: center;
  flex-wrap: wrap;
  margin: 30px -10px;
  padding-left: 1.45rem;
  li {
    box-sizing: border-box;
    display: inline;
    margin: 10px;
    flex: 0 0 auto;
    border: 4px solid transparent;
    border-radius: 20px;
    padding: 0;
    transition: background-color 200ms ease-in-out, border-color 200ms ease-in-out;
    a {
      padding: 10px;
      display: block;
      width: 100%;
      &:hover {
        color: #333;
      }
    }
    &:hover {
      background: var(--accent);
    }
  }
`

function Tags({ posts, post, tag, dark }) {
  const HomeIcon = FaHome
  const TagsIcon = FaTags
  if (tag) {
    return (
      <div>
        <PageHeading>
          {post.length} post{post.length === 1 ? "" : "s"} tagged with {tag}
        </PageHeading>
        <ul>
          {post.map(({ id, frontmatter, excerpt }) => {
            return (
              <Li key={id} dark={dark}>
                <SubHeading>
                  <Link to={frontmatter.path}>{frontmatter.title}</Link>
                </SubHeading>
                <p>{excerpt}</p>
              </Li>
            )
          })}
        </ul>
        <LinkContainer>
          <Link to="/tags">
            <TagsIcon /> All tags
          </Link>
        </LinkContainer>
        <LinkContainer>
          <Link to="/blog/">
            <HomeIcon /> All posts
          </Link>
        </LinkContainer>
      </div>
    )
  }
  return (
    <div>
      <PageHeading>Tags</PageHeading>
      <TagList>
        {Object.keys(posts).map(tagName => (
          <Li key={tagName}>
            <Link to={`/tags/${tagName}`}>{tagName}</Link>
          </Li>
        ))}
      </TagList>
      <LinkContainer>
        <Link to="/blog/">
          <HomeIcon /> All posts
        </Link>
      </LinkContainer>
    </div>
  )
}

export default function TagsTemplate(props) {
  const { pageContext } = props
  return (
    <Layout {...props}>
      <Tags {...pageContext} />
    </Layout>
  )
}
